import { PrismicRichText } from '@prismicio/react';
import * as prismicHelpers from '@prismicio/helpers';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { htmlSerializerEmbed } from '@yoweb/ui/utils/prismic';
import type { FaqProps } from '@yoweb/ui/components/sections/Faq';
import { Title } from '@yoweb/ui/components/typography';
import { Faq } from '@yoweb/ui/components/sections/Faq';
import { type FaqSliceResponse, requiredPrimaryFields, requiredItemsFields } from './FaqSliceTypes';

type FaqSliceProps = {
  slice: FaqSliceResponse;
} & Omit<FaqProps, 'data' | 'title'>;

const FaqSlice = ({ slice, ...rest }: FaqSliceProps) => {
  const props = {
    title: prismicHelpers.asText(slice.primary.sectionName) ?? 'Not set',
    background: slice.primary.background ?? 'sprout',
    navId: slice.primary.navId,
    data: slice.items.map((item, idx) => ({
      contentTitle: (
        <Title as="h3" size={{ _: 'xs', md: 'sm' }} weight="regular">
          {prismicHelpers.asText(item.question)}
        </Title>
      ),
      contentBody: (
        <PrismicRichText
          field={item.answer}
          components={htmlSerializerEmbed}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item?.questionId}-${idx}`}
        />
      ),
    })),
  };

  return <Faq {...props} {...rest} />;
};

export default withPrismicGuardian(FaqSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
