import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const CornerCardSectionSliceType = 'corner_card_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'mobileImage', 'image', 'subtitle'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  image: ImageField;
  tabletImage?: ImageField;
  mobileImage: ImageField;
  title: RichTextField;
  subtitle: RichTextField;
  label?: RichTextField;
  disclaimer?: RichTextField;
  ctaHref: string;
  ctaLabel: string;
  ctaLabelMobile?: string;
  infoRight: boolean;
  navId: string;
};

export type DefaultSliceResponse = PrismicSlice<
  typeof CornerCardSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'default-slice'
>;

export type TransparentBackgroundBlackTextSliceResponse = PrismicSlice<
  typeof CornerCardSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'transparentBackgroundBlackText'
>;

export type TransparentBackgroundWhiteTextSliceResponse = PrismicSlice<
  typeof CornerCardSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'transparentBackgroundWhiteText'
>;

export type CornerCardSectionSliceResponse =
  | DefaultSliceResponse
  | TransparentBackgroundBlackTextSliceResponse
  | TransparentBackgroundWhiteTextSliceResponse;
