import { forwardRef, type ForwardedRef } from 'react';
import styled, { type Colors } from 'styled-components';

import { Accordion } from '@yoweb/ui/components/marketing/Accordion';
import type { AccordionData } from '@yoweb/ui/components/marketing/Accordion';
import { ContainerWideColor } from '@yoweb/ui/components/marketing/ContainerWideColor';
import { Container } from '@yoweb/ui/components/containers';
import { Title } from '@yoweb/ui/components/typography';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { media } from '@yoweb/ui/styles/utils';
import { Box, type BoxProps } from '@yoweb/ui/components/Box';

export type FaqProps = {
  title: string;
  data: AccordionData[];
  isOpenIndex?: number;
  background?: keyof Colors;
  navId?: string;
} & DataTestId &
  Pick<BoxProps, 'pt' | 'pb' | 'py' | 'mt'>;

export const Faq = forwardRef<HTMLDivElement, FaqProps>(
  (
    { data, title, background = 'sprout', pt, pb, py, mt, isOpenIndex, navId, ...props }: FaqProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <Box data-testid={props['data-testid']} id={navId} ref={ref} pt={pt} pb={pb} py={py} mt={mt}>
      <ContainerWideColor bgColor={background}>
        <Container withGrid>
          <ContainerInner data-testid="faq-section" as="section">
            <Title as="h2" size={{ _: 'lg', md: 'xl' }}>
              {title}
            </Title>
            <AccordionWrapper>
              <Accordion data={data} isOpenIndex={isOpenIndex} id="faq" />
            </AccordionWrapper>
          </ContainerInner>
        </Container>
      </ContainerWideColor>
    </Box>
  ),
);

Faq.displayName = 'Faq';

const ContainerInner = styled.div`
  padding: ${getSpace('large1')} 0;
  grid-column: 1 / span 4;
  width: 100%;

  ${media.md`
    grid-column: 1 / span 8;
    margin: 0 auto;
    padding: ${getSpace('large3')} 0;
  `}

  ${media.lg`
    grid-column: 3 / span 8;

  `}
`;

const AccordionWrapper = styled.div`
  margin-top: ${getSpace('normal3')};
`;
