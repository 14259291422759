import { Element, type JSXFunctionSerializer, PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type CornerCardSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './CornerCardSectionSliceTypes';
import { blurParam } from '@yoweb/prismic-slicemachine/slices/slices.utils';
import { CornerCardSection } from '@yoweb/ui/components/marketing/CornerCardSection';
import { Text } from '@yoweb/ui/components/typography';
import type { TextProps } from '@yoweb/ui/components/typography/Text';
import {
  bulletpointSerializer,
  inverseBulletpointSerializer,
  disclaimerSerializer,
} from '@yoweb/prismic-slicemachine/utils/serializers';

/**
 * Determine how inverse variant label field RichText gets rendered.
 */
const labelSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  const commonProps: TextProps = {
    variant: 'inverse',
    size: { _: 'sm', lg: 'xl' },
  };

  switch (node.type) {
    case Element.paragraph:
      return (
        <Text key={_key} as="span" {...commonProps}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );

    case Element.strong:
      return (
        <Text key={_key} as="span" {...commonProps} weight="bold">
          {children}
        </Text>
      );

    default:
      return null;
  }
};

type CornerCardSectionSliceProps = {
  slice: CornerCardSectionSliceResponse;
} & DataTestId;

const CornerCardSectionSlice = ({ slice }: CornerCardSectionSliceProps) => {
  const {
    title,
    subtitle,
    image,
    ctaHref,
    ctaLabel,
    ctaLabelMobile,
    tabletImage,
    mobileImage,
    label,
    disclaimer,
    infoRight = false,
    navId,
  } = slice.primary;

  const commonProps = {
    image: {
      src: image.url || undefined,
      alt: image.alt ?? '',
      blurDataURL: `${image.url}&${blurParam}`,
    },
    tabletImage: {
      src: tabletImage?.url ?? image.url ?? '',
      alt: tabletImage?.alt ?? image.alt ?? '',
      blurDataURL: `${tabletImage?.url}&${blurParam}` ?? `${image.url}&${blurParam}`,
    },
    mobileImage: {
      src: mobileImage?.url ?? '',
      alt: mobileImage?.alt ?? '',
      blurDataURL: `${mobileImage?.url}&${blurParam}`,
    },
    title: <PrismicRichText field={title} components={bulletpointSerializer} />,
    subtitle: <PrismicRichText field={subtitle} components={bulletpointSerializer} />,
    ctaLabel,
    ctaLabelMobile,
    ctaHref,
    label: label ? <PrismicRichText field={label} components={labelSerializer} /> : undefined,
    disclaimer: <PrismicRichText field={disclaimer} components={disclaimerSerializer} />,
    variation: slice.variation,
    infoRight,
    navId,
  };

  if (slice.variation === 'transparentBackgroundBlackText') {
    return <CornerCardSection {...commonProps} backgroundColor="transparent" />;
  }

  if (slice.variation === 'transparentBackgroundWhiteText') {
    const props = {
      ...commonProps,
      title: <PrismicRichText field={title} components={inverseBulletpointSerializer} />,
      subtitle: <PrismicRichText field={subtitle} components={inverseBulletpointSerializer} />,
    };

    return <CornerCardSection {...props} backgroundColor="transparent" />;
  }

  return <CornerCardSection {...commonProps} />;
};

export default withPrismicGuardian(CornerCardSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
