import {
  Children,
  isValidElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { every, fill, update } from 'lodash-es';
import styled from 'styled-components';

import { ButtonLink } from '@yoweb/ui/components/buttons';
import {
  default as AccordionItem,
  type AccordionItemProps,
  AccordionItemStyleMode,
} from './AccordionItem';
import { getFontSize, getFontWeight, getSize, getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { useTranslation } from '@yoweb/next-i18next';
import type { AccordionData, AccordionItemData } from './AccordionTypes';

export type RefsObject = {
  [key: string]: HTMLDivElement | null;
};

type AccordionProps = {
  data: AccordionData[];
  // Pre opens specific item
  isOpenIndex?: number;
  id: string;
  refs?: React.MutableRefObject<RefsObject>;
  contentTitleIcon?: ReactNode;
  noDividers?: boolean;
  showExpandCollapseAll?: boolean;
  // In accordion mode, only one item can be expanded at a time.
  singleAccordionMode?: boolean;
  itemStyleMode?: AccordionItemStyleMode;
  renderItemTitle?: (
    data: AccordionData,
    item: AccordionItemData,
  ) => string | ReactNode | ReactNode[];
} & DataTestId;

const initExpandStates = (data: AccordionData[], idx: number | undefined) => {
  // Create an initial state for the expanded/collapsed state of each accordion item
  const initialState = new Array<boolean>(data.length).fill(false);
  // If idx is provided and within the range of the data array, set the corresponding item as open
  if ((idx || idx === 0) && initialState[idx] !== undefined) {
    initialState[idx] = true;
  }
  return initialState;
};

export const Accordion = ({
  data,
  isOpenIndex,
  id,
  refs,
  contentTitleIcon,
  noDividers = false,
  showExpandCollapseAll = false,
  singleAccordionMode = false,
  itemStyleMode = AccordionItemStyleMode.DEFAULT,
  renderItemTitle,
}: AccordionProps) => {
  const { t } = useTranslation<'common'>();
  // Recall the function if there is any changes in selected index or the length of items.
  const createInitialState = useCallback(
    () => initExpandStates(data, isOpenIndex),
    [data, isOpenIndex],
  );

  // Use useState to manage the expanded/collapsed state of the accordion items
  const [expandedState, setExpandedState] = useState(() => createInitialState());
  const [hasExpanded, setExpanded] = useState(false);

  // Update the expandedState if there is any change on createInitialState (selected index, the length of items)
  useEffect(() => {
    setExpandedState(createInitialState());
  }, [createInitialState]);

  // Handler for toggling the expanded/collapsed state of an accordion item
  const onToggleHandler: AccordionItemProps['onToggle'] = (index, isCollapsed) => {
    const inSingleAccordionMode = singleAccordionMode && !hasExpanded;
    let newExpandedState;

    if (inSingleAccordionMode) {
      newExpandedState = expandedState.map(() => false);
      newExpandedState[index] = isCollapsed;
      setExpandedState(newExpandedState);
    } else {
      newExpandedState = [...(update(expandedState, index, () => isCollapsed) as boolean[])];
      setExpanded(!expandedState.every((v) => v === false));
    }
  };

  // Check if all accordion items are expanded
  const allExpanded = useMemo(() => every(expandedState, Boolean), [expandedState]);

  // Function to toggle the expansion/collapse of all accordion items
  const toggleExpandAll = () => {
    setExpandedState(fill([...expandedState], !allExpanded));
    setExpanded(!hasExpanded);
  };

  return (
    <AccordionContainer data-testid={`${id}-accordion`}>
      {showExpandCollapseAll && (
        <ExpandCollapseAllContainer>
          <StyledButton
            text={allExpanded ? t('accordion.collapse-all') : t('accordion.expand-all')}
            onClick={toggleExpandAll}
          />
        </ExpandCollapseAllContainer>
      )}
      {Children.toArray(
        data.map((accordionData, index) => (
          <AccordionItem
            index={index}
            id={`accordion-item-${id}-${index}`}
            expanded={expandedState[index]}
            styleMode={itemStyleMode}
            renderTitle={renderItemTitle}
            {...{
              ...accordionData,
              ...(isValidElement(contentTitleIcon) ? { contentTitleIcon } : {}),
              noDividers,
            }}
            onToggle={onToggleHandler}
            refs={refs}
          />
        )),
      )}
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div``;

const ExpandCollapseAllContainer = styled.div`
  max-width: calc(${getSize('containerWidth')} + 2 * ${getSpace('normal2')});
  align-items: center;
  margin: auto;
  margin-bottom: ${pxToRem(20)};
  display: grid;
  grid-template-columns: repeat(24, 1fr);

  > button {
    grid-column: 20 / 24;
    ${media.lg`
      grid-column: 19 / 23;
    `}
  }
`;

const StyledButton = styled(ButtonLink)`
  margin-left: auto;
  display: block;
  font-size: ${getFontSize('bodySm')};
  font-weight: ${getFontWeight('bold')};
`;

export default Accordion;
