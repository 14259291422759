import styled, { css } from 'styled-components';

import { useTranslation } from '@yoweb/next-i18next';
import { Collapsible } from '@yoweb/ui/components/Collapsible';
import { getColor, getSize, getSpace, th } from '@yoweb/ui/styles/utils/theme';
import type { RefsObject } from './Accordion';
import type { AccordionData, AccordionItemData } from './AccordionTypes';
import { media, pxToRem } from '@yoweb/ui/styles/utils';

export enum AccordionItemStyleMode {
  DEFAULT = 0,
  FULL_PAGE = 1,
  JUSTIFIED = 2,
}

export type AccordionItemProps = {
  refs?: React.MutableRefObject<RefsObject>;
  onToggle(index: number, isCollapsed: boolean): void;
  noDividers?: boolean;
  styleMode?: AccordionItemStyleMode;
  renderTitle?: (data: AccordionData, item: AccordionItemData) => string | ReactNode | ReactNode[];
} & AccordionItemData &
  AccordionData;

const AccordionItem = ({
  expanded,
  onToggle,
  id,
  contentTitle,
  contentBody,
  index,
  refs,
  itemId,
  contentTitleIcon,
  noDividers,
  backgroundColor,
  styleMode = AccordionItemStyleMode.DEFAULT,
  renderTitle,
}: AccordionItemProps) => {
  const { t } = useTranslation(['common']);

  return (
    <AccordionItemContainer
      {...{ backgroundColor, noDividers }}
      ref={(element) => {
        if (refs?.current && itemId) refs.current[itemId] = element;
      }}
      stylemode={styleMode}
    >
      <StyledCollapsible
        id={id}
        isCollapsed={!expanded}
        onToggled={(isCollapsed) => onToggle(index, isCollapsed)}
        stylemode={styleMode}
        ariaLabel={t(
          expanded
            ? 'common:accordion-expanded-aria-label'
            : 'common:accordion-collapsed-aria-label',
        )}
        header={
          <Header>
            <TitleIconWrapper>
              {renderTitle
                ? renderTitle(
                    {
                      contentBody,
                      contentTitle,
                      backgroundColor,
                      contentTitleIcon,
                      itemId,
                    },
                    {
                      id,
                      expanded,
                      index,
                      itemId,
                      noDividers,
                    },
                  )
                : contentTitle}
            </TitleIconWrapper>
          </Header>
        }
      >
        <StyledContentContainer>
          <Content stylemode={styleMode}>{contentBody}</Content>
        </StyledContentContainer>
      </StyledCollapsible>
    </AccordionItemContainer>
  );
};

const AccordionItemContainer = styled.div<{
  noDividers: boolean | undefined;
  backgroundColor: string | undefined;
  stylemode: AccordionItemStyleMode;
}>`
  margin: auto;

  ${({ noDividers }) =>
    !noDividers &&
    css`
      border-bottom: 1px solid ${getColor('base900')};
    `};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
`;

const StyledCollapsible = styled(Collapsible)<{ stylemode: AccordionItemStyleMode }>`
  max-width: calc(${getSize('containerWidth')} + 2 * ${getSpace('normal2')});
  margin: auto;

  > button {
    display: grid;
    grid-column-gap: ${th.getSize('gridGap')};
    grid-template-columns: repeat(12, 1fr);
    padding: 0 ${getSpace('normal2')};
    ${media.lg`
      padding: 0;
    `}
  }

  ${({ stylemode }) =>
    stylemode === AccordionItemStyleMode.JUSTIFIED &&
    css`
      > button {
        padding: 0;
      }
    `}

  > button > header {
    grid-column: 1 / 12;

    ${media.lg`
      grid-column: 2 / 10;
    `}
  }

  > button > div {
    grid-column: 12 / 12;
    justify-self: end;
  }

  ${({ stylemode }) =>
    (stylemode === AccordionItemStyleMode.DEFAULT ||
      stylemode === AccordionItemStyleMode.JUSTIFIED) &&
    css`
      > button {
        ${media.lg`
          padding-left: ${pxToRem(24)};
          padding-right: ${pxToRem(24)};
        `}
      }

      > button > header {
        grid-column: 1 / 12;
      }
    `}

  ${({ stylemode }) =>
    stylemode === AccordionItemStyleMode.FULL_PAGE &&
    css`
      > button {
        min-height: ${pxToRem(98)};
        ${media.lg`
          min-height: ${pxToRem(105)};
        `}
      }

      > button > header {
        ${media.lg`
          grid-column: 2 / 10;
        `}
      }

      > button > div {
        ${media.lg`
          grid-column: 10 / 12;
        `}
      }
    `}
`;

const Header = styled.header`
  padding: ${getSpace('normal2')} 0;
`;

const TitleIconWrapper = styled.header`
  display: flex;
  align-items: center;
  column-gap: ${getSpace('small4')};
`;

const StyledContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${getSpace('normal2')};
  align-items: center;
`;

const Content = styled.div<{ stylemode: AccordionItemStyleMode }>`
  grid-column: 2 / span 10;
  padding-bottom: ${getSpace('normal2')};
  ${({ stylemode }) =>
    stylemode === AccordionItemStyleMode.DEFAULT &&
    css`
      grid-column: 1 / span 12;
      padding-left: ${pxToRem(24)};
      padding-right: ${pxToRem(24)};
    `}

  ${({ stylemode }) =>
    stylemode === AccordionItemStyleMode.JUSTIFIED &&
    css`
      grid-column: 1 / span 12;
      padding: 0;

      ${media.lg`
        padding-left: ${pxToRem(24)};
        padding-right: ${pxToRem(24)};
      `}
    `}
`;

export default AccordionItem;
