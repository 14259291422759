import type { ReactNode } from 'react';
import styled, { css, type Colors } from 'styled-components';
import Image, { type ImageProps } from 'next/legacy/image';
import { transparentize } from 'polished';
import Link from 'next/link';

import { Container } from '@yoweb/ui/components/containers';
import { Button } from '@yoweb/ui/components/buttons';
import { Title, Text } from '@yoweb/ui/components/typography';
import { getSpace, getZIndex, th } from '@yoweb/ui/styles/utils/theme';
import { media, gridBase, pxToRem } from '@yoweb/ui/styles/utils';
import AnimateIn, { InView, STAGGER_DELAY, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { Box } from '@yoweb/ui/components/Box';

export type CornerCardSectionProps = {
  image: Partial<ImageProps>;
  tabletImage?: ImageProps;
  mobileImage: ImageProps;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  ctaHref?: string;
  ctaLabel?: string;
  ctaLabelMobile?: string;
  label?: ReactNode;
  backgroundColor?: keyof Colors;
  variation?: string;
  disclaimer?: ReactNode;
  noCard?: boolean;
  infoRight: boolean;
  navId?: string;
} & DataTestId;

const imageDefaults: Partial<ImageProps> = {
  src: '',
  alt: '',
  layout: 'fill',
  objectFit: 'cover',
  objectPosition: 'center center',
  placeholder: 'blur',
};

/**
 * Section with white cta card in the left bottom corner
 */
export const CornerCardSection = ({
  image = imageDefaults,
  tabletImage,
  mobileImage,
  title,
  subtitle,
  ctaHref,
  ctaLabel,
  ctaLabelMobile,
  label,
  backgroundColor,
  variation,
  disclaimer,
  noCard,
  infoRight,
  navId,
}: CornerCardSectionProps) => {
  const defaultImageOpts: ImageProps = { ...imageDefaults, ...image } as ImageProps;
  const tabletImageOpts: ImageProps = { ...imageDefaults, ...tabletImage } as ImageProps;
  const mobileImageOpts: ImageProps = { ...imageDefaults, ...mobileImage } as ImageProps;

  return (
    <SectionWrapper id={navId} as="section">
      <BackgroundWrapper>
        <DesktopImage>
          <Image {...defaultImageOpts} />
        </DesktopImage>
        {tabletImage && (
          <TabletImage>
            <Image {...tabletImageOpts} />
          </TabletImage>
        )}
        <MobileImage withTabletImage={!!tabletImage}>
          <Image {...mobileImageOpts} />
        </MobileImage>
      </BackgroundWrapper>
      {!noCard && (
        <Container>
          <Content data-testid="corner-card-section">
            <InfoCardContainer displayRight={infoRight}>
              <InView>
                {(isInView) => (
                  <>
                    {label && (
                      <LabelWrap infoDisplayRight={infoRight}>
                        <AnimateIn isVisible={isInView}>{label} </AnimateIn>
                      </LabelWrap>
                    )}
                    <StaggerElements isInView={isInView}>
                      <InfoCard backgroundColor={backgroundColor}>
                        <StaggerElements isInView={isInView} initialDelay={STAGGER_DELAY}>
                          {typeof title === 'string' ? (
                            <TitleInfo
                              size={{ _: 'sm', lg: 'md' }}
                              data-testid="corner-card-section-title"
                            >
                              {title}
                            </TitleInfo>
                          ) : (
                            <div data-testid="corner-card-section-title">{title}</div>
                          )}
                          <Box mt={{ _: 'small3', md: 'normal2' }}>
                            {typeof subtitle === 'string' ? (
                              <TextInfo
                                size={{ _: 'lg', md: 'md', lg: 'lg' }}
                                data-testid="corner-card-section-text"
                                variant="muted"
                              >
                                {subtitle}
                              </TextInfo>
                            ) : (
                              <SubtitleContainer
                                hasCTA={Boolean(ctaHref && ctaLabel)}
                                data-testid="corner-card-section-text"
                              >
                                {subtitle}
                              </SubtitleContainer>
                            )}
                          </Box>
                          {ctaHref && ctaLabel && (
                            <Link href={ctaHref ?? ''} passHref legacyBehavior>
                              <Button
                                as="a"
                                href={ctaHref}
                                size="md"
                                data-testid="corner-card-section-button"
                                mode={
                                  variation === 'transparentBackgroundWhiteText' ? 'dark' : 'light'
                                }
                              >
                                <DesktopText>{ctaLabel}</DesktopText>
                                <MobileText>{ctaLabelMobile}</MobileText>
                              </Button>
                            </Link>
                          )}
                          {disclaimer && <Box mt="normal1">{disclaimer}</Box>}
                        </StaggerElements>
                      </InfoCard>
                    </StaggerElements>
                  </>
                )}
              </InView>
            </InfoCardContainer>
          </Content>
        </Container>
      )}
    </SectionWrapper>
  );
};

const Content = styled.div`
  ${gridBase};

  align-items: end;
  height: 100%;
  margin-bottom: ${getSpace('normal2')};
  position: relative;

  ${media.lg`
      margin-bottom: ${getSpace('large2')};
  `}
`;

const TitleInfo = styled(Title)`
  white-space: pre-line;
  margin-bottom: ${pxToRem(12)};
  ${media.md`
      margin-bottom: ${getSpace('small3')};
    `}
  ${media.lg`
      margin-bottom: ${pxToRem(20)};
    `}
`;
const TextInfo = styled(Text)`
  && {
    margin-bottom: ${getSpace('normal2')};

    ${media.lg`
      margin-bottom: ${getSpace('normal3')};
    `}
  }
`;

const SubtitleContainer = styled.div<{ hasCTA: boolean }>`
  ${({ hasCTA }) =>
    !hasCTA &&
    css`
      && {
        p {
          margin-bottom: unset;
        }
      }
    `}
`;

const InfoCardContainer = styled.div<{ displayRight: boolean }>`
  grid-column: 1/5;

  ${media.md`
    grid-column: 1/6;
  `}

  ${({ displayRight }) =>
    displayRight &&
    css`
      ${media.sm`
        grid-column: 1/5;
      `}
      ${media.md`
        grid-column: 4/9;
      `}
      ${media.lg`
        grid-column: 8/13;
      `}
    `}
`;

const InfoCard = styled.div<{ backgroundColor?: keyof Colors }>`
  background-color: ${({ backgroundColor = 'base000' }) => th.getColor(backgroundColor)};
  border-radius: ${th.getRadii('medium')};
  display: grid;
  justify-items: flex-start;
  padding: ${pxToRem(32)} ${pxToRem(24)} ${pxToRem(38)} ${pxToRem(24)};

  ${media.lg`
    padding: ${pxToRem(56)} ${pxToRem(40)} ${pxToRem(56)} ${pxToRem(48)};
  `}
`;

const SectionWrapper = styled.div`
  position: relative;
  height: 100vh;
  background: ${th.getColor('base100')};
  display: flex;
  justify-content: flex-end;

  ${media.md`
    height: 100vh;
  `}

  ${media.lg`
    height: 100vh;
  `}
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  background: ${th.getColor('base700')};
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    ${({ theme }) => css`
      background: linear-gradient(
        180deg,
        ${transparentize(1, theme.colors.base700)} 0%,
        ${theme.colors.base700} 18%
      );
      z-index: ${getZIndex('above')};
    `}
  }
`;

const LabelWrap = styled.div<{ infoDisplayRight: boolean }>`
  padding-bottom: ${getSpace('normal1')};

  ${media.md`
  position: absolute;
  bottom: 0;
  right: 0;
    padding-bottom: 0;
  `}

  ${({ infoDisplayRight }) =>
    infoDisplayRight &&
    css`
      right: unset;
      left: 0;
    `}
`;

const MobileImage = styled.div<{ withTabletImage: boolean }>`
  display: block;
  height: 100%;
  position: relative;
  width: 100%;

  ${({ withTabletImage }) =>
    withTabletImage
      ? css`
          ${media.md`
            display: none;
          `}
        `
      : css`
          ${media.lg`
            display: none;
          `}
        `}
`;

const TabletImage = styled.div`
  display: none;
  height: 100%;
  position: relative;
  width: 100%;

  ${media.md`
    display: block;
  `}
  ${media.lg`
    display: none;
  `}
`;

const DesktopImage = styled.div`
  display: none;
  height: 100%;
  position: relative;
  width: 100%;

  ${media.lg`
    display: block;
  `}
`;

const DesktopText = styled.span`
  display: none;

  ${media.sm`
    display: block;
  `}
`;

const MobileText = styled.span`
  display: block;

  ${media.sm`
    display: none;
  `}
`;

export default CornerCardSection;
