import type { Colors } from 'styled-components';
import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const FaqSliceType = 'faq_slice';

// Required fields in slice
export const requiredPrimaryFields = ['sectionName'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['question', 'answer'] as const; // Array of all required items fields.

export type Primary = {
  sectionId?: string;
  sectionName: RichTextField;
  background?: keyof Colors;
  navId?: string;
  navLabel?: string;
};

export type Item = {
  questionId?: string;
  question: RichTextField;
  answer: RichTextField;
};

export type FaqSliceResponse = PrismicSlice<
  typeof FaqSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
